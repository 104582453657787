import '../styles/ThankYou.css'

export default function ThankYou({ email, fName }: { email: string, fName: string }){
    return (
        <div className='widget__thankyou__ctn'>
            <div className='thankyou__header'>
                <p className='thankyou__header__checkmark'>✓</p>
                <h1 className='thankyou__header__heading'>Your registration is complete, <span>{fName}!</span></h1>
            </div>

            <div className='thankyou__footer'>
                <h2 className='thankyou__footer__heading'>What happens next?</h2>
                <ul className='thankyou__footer__list'>
                    <li>myDNA will send a confirmation shortly to <strong>{email}</strong>.</li>
                    <li>Be sure to <strong>send your sample</strong> off as soon as possible to myDNA's lab! Use the reply-paid envelope included in your kit.</li>
                    <li>We'll be in touch via your <strong>Sister Club email</strong> once your Skin Profile and results are ready. To ensure you receive your results, take a minute to <a target='_blank' rel='noreferrer' href='https://www.priceline.com.au/customer/sisterclub/comm'>check your details are up to date</a> and you are opted in to receive Sister Club emails.</li>
                </ul>
                <a className='thankyou__footer_button' href='https://www.priceline.com.au/'>Return to Priceline</a>
                <div className='widget__logo'>
                    <img 
                        src='/priceline_pharmacy-logo.svg'
                        alt='Priceline Logo'
                        className='widget__logo--priceline'
                    />
                    <img 
                        src='/powered_by_mydna-logo.svg'
                        alt='myDNA Logo'
                        className='widget__logo--myDNA'
                    />
                </div>
            </div>
        </div>
    )
}