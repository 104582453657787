import { useEffect, useState } from 'react'

import '../styles/Popup.css'

export type Props = {
    children: JSX.Element,
    startOpened?: boolean,
    close: () => void
}

export default function Popup({ children, startOpened, close }: Props){
    //handle fade in
    const [visible, setVisible] = useState(startOpened ?? false);
    useEffect(() => setVisible(true), []);

    //close after fade out delay
    const delayClose = (e: React.MouseEvent) => {
        e.preventDefault();
        setVisible(false);
        setTimeout(close, 305);
    }

    return (
        <div className={"popup" + (visible ? ' visible' : '')} onClick={delayClose}>
            <div 
                className='popup__inner'
                onClick={e => e.stopPropagation()}
            >
                <button 
                    aria-label='Close Button'
                    className='popup__inner__close'
                    onClick={delayClose}
                >
                    x
                </button>
                <div className='popup__inner__children'>
                    { children }
                </div>
            </div>
        </div>
    )
}