import { Field } from "../types/FormField";

const W3CemailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export function validateField(field: Field, value: string | boolean){
    //mandatory checkbox is unchecked
    if(field.fieldType === 'checkbox' && field.required && value !== true) return false;

    //mandatory field is empty
    if(field.required && !value) return false;

    if(typeof value === 'string'){
        //value is too long
        if(field.maxLength && value.length > field.maxLength) return false;

        //check if within min/max constraints
        if(field.max && typeof field.max === 'number' && parseInt(value) > field.max) return false;
        if(field.max && typeof field.max === 'object' && new Date(value) > new Date(field.max)) return false;
        if(field.min && typeof field.min === 'number' && parseInt(value) < field.min) return false;
        if(field.min && typeof field.min === 'object' && new Date(value) < new Date(field.min)) return false;

        //check if matches pattern
        if(field.pattern?.test(value) === false) return false;

        //check if valid email
        if(field.fieldType === 'email' && !W3CemailRegex.test(value)) return false;
    }

    return true;
}