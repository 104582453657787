import '../styles/Header.css'

export default function Header(){
    return (
        <>
            <header className='header'>
                <div className='header__ctn'>
                    <div className='header__logo'>
                        <img 
                            src='/priceline_pharmacy-logo.svg'
                            alt='Priceline Logo'
                            className='header__logo--priceline'
                            width='140'
                            height='40'
                        />
                        <img 
                            src='/powered_by_mydna-logo.svg'
                            alt='myDNA Logo'
                            className='header__logo--myDNA'
                            width='100'
                            height='35'
                        />
                    </div>
                    <img
                        src='/know_your_skin-logo.svg'
                        alt='Know Your Skin logo'
                        className='header__logo--know-your-skin'
                        height='90'
                    />
                </div>
                <img 
                    src='/wavy_line.svg' 
                    alt=''
                    className='header__wavy-line--outer'
                />
            </header>
        </>
    )
}