import '../styles/InformedConsent.css'

export default function InformedConsent(){
    return (
        <div className="informed-consent">
            <h2>Informed Consent</h2>

            <h3>Introduction</h3>
            <p>This Informed Consent reviews the benefits, risks and limitations of undergoing a genetic test provided by Australian Pharmaceutical Industries Limited (under the retail banner of Priceline Pharmacy “Priceline”) in partnership with myDNA Life Ltd (“myDNA”).</p>
            <p>Throughout this Informed Consent, “you”, “your”, “me”, “my”, and “I” refer to the person whose information and sample is being provided for this Test. If you are a parent or guardian requesting a Test for a minor, “you” may also refer to “your child”, as contextually appropriate. By proceeding with the registration, you confirm and acknowledge that you have read, understood, and agree to this Informed Consent. You understand that the Service is voluntary and that you are not required to have the Service.</p>

            <h3>What we collect and how it is used</h3>
            <p>If you request a Service, you will be asked to provide your Identification Details (ID)). In order for the Service to perform as intended, you must provide accurate and correct information. If another person is submitting your IDI or related information on your behalf, by agreeing to this Informed Consent, you represent and warrant that such person is authorised to provide such information, and that all such information is accurate and correct.</p>
            <p>You will also be asked to provide a DNA sample in accordance with the collection procedures of the myDNA. Your sample and ID will be transferred to the laboratory. DNA will be extracted from the submitted sample and analysed. Genetic data includes information about genes and regions relevant to the ordered Test. Reporting on genes and regions beyond those relevant to the ordered Test will not occur unless you consent to future products or services. Genetic Results from the myDNA will be securely and confidentially transferred referred to Priceline.</p>

            <h3>Benefits of the Services</h3>
            <p>Depending on the Test ordered, results may give you further understanding of general health and wellness insights. Such insights may allow you to use your Genetic Information to explore personalised solutions to reach your goals. Genetics is only one part of the picture, and so the value comes from understanding your genetic profile so that you can make sustainable changes that support your own goals, lifestyle and environment. The test results will be sent to you by Priceline.</p>

            <h3>Limitations of the Services</h3>
            <p>Analysis of results is performed in myDNA’s Laboratory and the results are securely transferred to Priceline. Interpretation is based on currently available information in the medical literature and scientific databases, as well as informatics and algorithms that may be subject to change. New information may replace or add to the information that was used to analyse your results. Based on this new information and/or modifications to our laboratory informatics and algorithms, you understand and agree that myDNA may amend or modify your insights and recommendations after delivery of your results.</p>
            <p>Please note that this test does not provide diagnosis, or treatment. The report and comments are for informational purposes only and should not be interpreted as specific professional medical advice. Please speak with your healthcare provider for further advice and before making any changes to your diet or lifestyle or before starting and stopping any treatment recommended for you. This report is based solely on the sample and information provided to myDNA and does not take all factors related to your health into account. Therefore, myDNA, and by extension Priceline, employees shall have no liability to any person or entity with regards to claims, loss, or damage caused, or alleged to be caused, directly or indirectly, by the use of information contained herein.</p>

            <h3>Privacy and data security</h3>
            <p>Your privacy is important to us. We comply with Privacy Act 1988 (Cth), as amended, including the Australian Privacy Principles. By agreeing to this Informed Consent, you acknowledge that such laws and regulations regarding collection, use, processing, and storage of your Information shall govern our performance of the Service and handling of your sample and information.</p>
            <p>Your genetic data and any reports generated will be treated as your property and will never be disclosed or shared with third parties without your explicit consent.</p>
            <p>While we cannot guarantee that unauthorised access, disclosure, misuse or loss of Information will never occur, we implement and frequently review physical and technical safeguards that are designed to protect the confidentiality, integrity and availability of your Information and to prevent information security incidents. In the event of a security incident or data breach, we will follow internal procedures to investigate the matter as well as comply with all applicable regulatory requirements.</p>

            <h3>Use of your information</h3>
            <p>Upon completion of your test, you are requesting that your results be made available to you via your Priceline Sister Club account. You also agree that Priceline or myDNA may communicate with you about your collection kit, order, results, account details, and other Test logistics and procedures.</p>
            <p>We store your genetic results and related data as required by applicable laws and regulations, and we may use this data to conduct subsequent testing or analysis in the event that you consent to any new product or service offering. We may analyse your deidentified results for evaluation, research and marketing purposes.</p>
            
            <h3>DNA sample retention</h3>
            <p>All DNA samples are stored at myDNA’s Laboratory. A DNA sample may be kept by myDNA unless or until circumstances require us to destroy the DNA sample, which you can request at any time by contacting myDNA, or it is no longer suitable for testing purposes. myDNA may store the DNA samples for a period of ten years for additional genetic testing, always subject to obtaining your explicit approval in advance for such additional testing.</p>
            <p>Your genetic data will be treated as your property and will never be disclosed or shared with third parties without your explicit consent. You can formally request for your sample to be destroyed at any time by contacting the myDNA.</p>
            <p>You understand that legal requirements regarding DNA samples (collection, handling, and retention) may change at any time. You understand that the existing legal and regulatory framework around DNA samples is complex and that if you wish to know more you should consult your own legal counsel.</p>

            <h3>DNA sample retention</h3>
            <p>The sample being provided is mine and I am at least 18 years of age, or if consenting for someone else, I have the legal authority to consent for such person whose sample is being provided. If the sample being provided is from someone under the age of 18, I represent that I am the parent or legal guardian of the person being tested.</p>
            <p>My sample will be analysed for the genes and regions identified in the Service(s) selected. My genetic information, personal data and results may be stored and used by Priceline and myDNA for regulatory compliance purposes. Additional analysis and reporting of genes and regions beyond those relevant to the ordered Test will not occur, unless I consent to future products or services.</p>
            <p>My sample and all my related personal information will be transferred to the myDNA’s Laboratory for analysis, use, processing, and storage. myDNA will conduct testing, analysis and interpretation of my genetic information and Priceline will provide me with personalised insights and recommendations. In some cases, an additional DNA sample may be required if the volume or quality of the initial sample is not adequate.</p>
            <p>My reports and genetic data will be treated as my property and will never be disclosed or shared with third parties without my explicit consent.  I can formally request for my sample to be destroyed at any time by contacting myDNA.</p>
            <p>I agree to the myDNA Terms of Use and Privacy Statement, which are available upon request.</p>
            <p>I confirm that all information provided is true and accurate. I agree that I have the authority to provide this consent. I understand that if I wish to withdraw consent, I may do so by contacting Priceline.</p>
        </div>
    )
}