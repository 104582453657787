import React, { useState } from "react"

import '../styles/FormField.css'

import { Field } from "../types/FormField"
import { validateField } from "../utils/validateForm";

export default function FormField(props: Field) {
    const {
        autocomplete,
        children,
        disabled,
        errorText,
        fieldType,
        fieldName,
        id,
        labelName,
        min,
        max,
        maxLength,
        prefillValue,
        pattern,
        placeholder,
        registerValue,
        required
    } = props;

    //get default from props, otherwise use false for checkboxes and an empty string for text fields
    const defaultValue = prefillValue ?? (fieldType === 'checkbox' || fieldType === 'radio') ? false : '';

    //create single source of truth for value state
    const [fieldValue, setFieldValue] = useState<string | boolean>(defaultValue);

    //handle changes to field value
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //value should be a boolean for checkboxes/radio buttons, otherwise a string
        let val: string | boolean = '';
        if (e.target.type === 'checkbox' || e.target.type === 'radio') {
            val = e.target.checked;
        } else if (e.target.value.length <= (maxLength ?? Infinity)) {
            val = e.target.value;
        }
        
        setFieldValue(val ?? '');

        //pass up to parent so all form values can be collected
        registerValue(prevState => {
            //spread new value into form
            const newValue = {
                ...prevState,
                values: {
                    ...prevState.values,
                    [id]: val
                }
            }
            return newValue;
        });
    };

    return (
        <>
            <div className="form__field__ctn">
                <span>
                    <input
                        id={id}
                        className={fieldType === 'checkbox' ? 'form__field__checkbox' : 'form__field__input'}
                        type={fieldType}
                        name={fieldName}
                        value={typeof fieldValue === 'string' ? fieldValue : undefined}
                        onChange={handleChange}
                        disabled={disabled ?? false}
                        min={min && typeof (min) === "object" ? min.toISOString().slice(0, 10) : min}
                        max={max && typeof (max) === "object" ? max.toISOString().slice(0, 10) : max}
                        maxLength={maxLength}
                        pattern={pattern?.toString().substring(1, pattern.toString().length - 1)} //trim brackets from RegExp
                        placeholder={placeholder}
                        required={required}
                        autoComplete={autocomplete === false ? 'off' : 'on'} // check for false since it's possible it could be undefined (and should default to true)
                    />

                    {labelName &&
                        <label
                            className={fieldType === 'checkbox' ? "form__field__checkbox__label" : "form__field__input__label"}
                            htmlFor={id}
                        >
                            {labelName}
                        </label>
                    }

                    { children }
                </span>

                {!validateField(props, fieldValue) &&
                    <p
                        className="form__field__error-text"
                        style={
                            {
                                display: fieldValue && !validateField(props, fieldValue) ? 'inherit' : 'none'
                            }
                        }
                    >
                        { errorText ?? 'Please provide a valid input for this field.' }
                    </p>
                }
            </div>
        </>
    )
}