import '../styles/Widget.css'
import Form from './Form'

export default function Widget(){

    return (
        <div className='widget'>
            <Form />
        </div>
    )
}