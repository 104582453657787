import { BarcodeDetailsResponse, commitRegistration200, commitRegistration400 } from "../types/barcodeDetailsResponse";
import { FormType } from "../types/Form";

//name of registration portal
const registrationPortal = 'Priceline';

//generic pretty error message for server errors
const genericError = 'A sever error occurred when submitting your registration. <strong>Your kit has not been activated</strong>. Please try submitting again. If this error occurs repeatedly, please <a href="https://support.mydna.life/hc/requests/new" target="_blank" rel="noreferrer">contact support</a>.';

export const submitRegistration = async (form: FormType) => {
    //check all required values supplied
    if(!formIsValid(form)) throw new Error('Form missing required values. Please check every required field has a valid entry and try again.');

    //validate barcode
    const barcode = form.values['form__activation'] as string;
    const barcodeResponse = await validateBarcode(barcode).catch(err => { throw new Error(err) });

    //submit registration
    const registrationResponse = await commitRegistration(form).catch(err => { throw new Error(err) });

    return {
        barcodeDetails: barcodeResponse,
        registrationDetails: registrationResponse
    };
}


const formIsValid = (form: FormType) => {
    //required properties
    const required = [
        'form__activation',
        'form__dob',
        'form__email',
        'form__fname',
        'form__lname',
        'form__club-no',
        'form__consent'
    ]

    //check values exist for each property
    for(const item of required){
        if(!form.values[item]) return false
    }

    return true;
}


const validateBarcode = async (barcode: string) => {
    const endpointURL = process.env.REACT_APP_CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL + '/api/RegisterMicroService/Register/GetBarcodeDetails';

    const url = `${endpointURL}?barcode=${barcode}&registrationPortalName=${registrationPortal}`;

    const res = await fetch(url).catch(() => {throw new Error(genericError);});
    const data: BarcodeDetailsResponse = await res.json();

    const {errorMessage, barcodeStatus, kitTypeId} = data;

    //check if any errors were returned from the server
    if(errorMessage.length > 0) throw new Error(errorMap(errorMessage));

    //check if kit is still active
    if(barcodeStatus !== 'Active') throw new Error(`This barcode is not currently active. Please check your activation code and <a href="https://support.mydna.life/hc/requests/new">contact myDNA</a> if this doesn't seem right.`);

    //check if correct kit type
    const pricelineKitId = 20028;
    if(kitTypeId !== pricelineKitId) throw new Error('Your product is not a Priceline DNA Collection kit. Please try activating your kit at our <a href="https://subscribe.mydna.life/">myDNA Registration Portal</a>.');

    return data;
}


type RegistrationBody = {
    barcode: string,
    firstName: string,
    lastName: string,
    dob: string,
    emailAddress: string,
    sisterClubNumber: string,
    marketingOptIn: boolean
}

const commitRegistration = async (form: FormType) => {
    const values = form.values;

    //map form values to required format
    const body: RegistrationBody = {
        barcode: values['form__activation'] as string,
        dob: values['form__dob'] as string,
        emailAddress: values['form__email'] as string,
        firstName: values['form__fname'] as string,
        lastName: values['form__lname'] as string,
        marketingOptIn: values['form__marketing-consent'] as boolean | undefined ?? false,
        sisterClubNumber: values['form__club-no'] as string,
    }

    const endpointURL = process.env.REACT_APP_CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL + '/api/PricelineRegistration/Register/CommitRegistrationDetails'

    const headers = new Headers();
    headers.append('Accept', 'text/json');
    headers.append('Content-Type', 'application/json');

    //send request
    const res = await fetch(endpointURL, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers
    }).catch(err => { throw new Error(genericError) });

    const data = await res.json();

    switch(res.status){
        case 200:
            return data as commitRegistration200;
        case 400:
            throw new Error((data as commitRegistration400).errors.map(e => e.description).join(' '));
        case 500:
        default:
            throw new Error(genericError);
    }
}


type ErrorMapping = {
    serverResponse: string,
    mappedOutput: string
}

const errorMap = (errorMessage: string[]) => {
    //list of errors and what the client should show instead of the error message
    const potentialErrors: ErrorMapping[] = [
        {
            serverResponse: "Operation returned an invalid status code 'ServiceUnavailable'",
            mappedOutput: 'A server error occurred: the myDNA registration service is temporarily unavailable. <strong>Your kit has not been activated</strong>. If this error occurs repeatedly, please <a href="https://support.mydna.life/hc/requests/new" target="_blank" rel="noreferrer">contact support</a>.'
        },
        {
            serverResponse: "Unfortunately your Activation Code doesn't look right, please re-enter and try again. If this still doesn't work, please contact us.",
            mappedOutput: 'The activation code you supplied is incorrect. Please check for typos and try again. If the problem persists, please <a href="https://support.mydna.life/hc/requests/new" target="_blank" rel="noreferrer">contact support</a>.'
        },
        {
            serverResponse: "Unfortunately this Activation Code has already been subscribed. If this does not seem right, please contact us.",
            mappedOutput: `Your activation code has already been registered. Priceline will be in contact via your Sister Club account once your results are ready. If this doesn't seem right, please <a href="https://support.mydna.life/hc/requests/new" target="_blank" rel="noreferrer">contact support</a>.`
        }
    ]

    //if known error, map to prettier output
    for(const error of potentialErrors){
        if(errorMessage.indexOf(error.serverResponse) > -1) return error.mappedOutput;
    }

    //otherwise return generic error
    return genericError;
}