import { useState } from 'react';

import '../styles/Form.css'

import FormField from './FormField'
import ThankYou from './ThankYou';
import Popup from './Popup';
import InformedConsent from './InformedConsent';

import { FormType } from '../types/Form';
import { submitRegistration } from '../utils/validateBarcode';

export default function Form(){
    //create object to contain form values
    const [formValues, setFormValues] = useState<FormType>({submitted: false, values: {}});
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [formSubmitError, setFormSubmitError] = useState<JSX.Element | undefined>();

    function handleSubmit(e: React.FormEvent<HTMLFormElement>){
        //prevent default HTML form submission behaviour
        e.preventDefault();

        //update button loading state
        setFormLoading(true);

        submitRegistration(formValues)
            .then(data => {
                //set form to submitted so thank you can be shown
                setFormValues(prevValue => ({...prevValue, submitted: true}));
            })
            .catch(e => {
                //reset button to be submittable
                setFormLoading(false);

                //display error message
                setFormSubmitError(e instanceof Error 
                    ? <span dangerouslySetInnerHTML={{__html: e.message}}></span>
                    : <>An error occurred. Please try again.</>
                );
            });
    }

    //state to see if the user has tried to submit the form yet
    //used to force show contextual hints if there are invalid fields
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => setAttemptedSubmit(true);

    //handle activation popup
    const [showActivationPopup, setActivationPopup] = useState(false);
    const toggleActivation = () => setActivationPopup(prevState => !prevState);

    //handle activation popup
    const [showSisterClubPopup, setSisterClubPopup] = useState(false);
    const toggleSisterClub = () => setSisterClubPopup(prevState => !prevState);

    //handle informed consent popup
    const [showConsentPopup, setConsentPopup] = useState(false);
    const toggleConsent = () => setConsentPopup(prevState => !prevState);

    //label for privacy checkbox
    const privacy = <>
        <span>I Agree to Priceline &amp; myDNA's <button className='form__consent__button' onClick={e => {e.preventDefault(); toggleConsent();}}>Informed Consent</button> and <a href='https://www.priceline.com.au/priceline-policies/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>.</span>
    </>

    //label for marketing checkbox
    const marketingConsent = <>
        <span>I'd like to hear more about other myDNA products & services.</span>
    </>

    //render thank you message if successfully submitted
    if(formValues.submitted){
        //read user email from props, otherwise generic fallback
        const email = typeof formValues.values['form__email'] === 'string' ? formValues.values['form__email'] : 'your email';

        //read first name from props, otherwise generic fallback
        const fName = typeof formValues.values['form__fname'] === 'string' ? formValues.values['form__fname'] : 'thanks';

        return (
            <ThankYou email={email} fName={fName} />
        )
    }

    //render form
    return (
        <div className='widget__form__ctn'>
            <h1 className='widget__heading'>Activate your Priceline DNA Test</h1>
            <h2 className='widget__subheading'>You're about to discover how your DNA affects the way your skin is likely to age with personalised DNA Skin Ageing insights.</h2>

            <form
                className={`widget__form ${attemptedSubmit ? 'attempted-submit' : ''}`}
                onSubmit={handleSubmit}
            >
                <fieldset className='form__section'>
                    <legend className='form__section__heading'>Complete your details</legend>
                    <FormField 
                        id='form__activation'
                        fieldName='form__activation'
                        fieldType='text'
                        labelName='Activation Code'
                        required={true}
                        maxLength={10}
                        pattern={new RegExp('[0-9]{10}')}
                        registerValue={setFormValues}
                        errorText='Please enter a valid activation code. It should be a 10-digit number which was included inside your kit.'
                    >
                        <div 
                            className='form__field__info-icon'
                            aria-label='Information icon'
                            title='What is this?'
                            onClick={toggleActivation}
                        >
                                <span>i</span>
                        </div>
                    </FormField>

                    { showActivationPopup && 
                        <Popup close={toggleActivation}>
                            <>
                                <img 
                                    src="/priceline-barcode-location.jpg" 
                                    alt='a diagram showing the location of the activation code on your kit sticker' 
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        maxWidth: '300px',
                                        margin: '0 auto 2em auto'
                                    }}
                                />
                                <p style={{
                                    textAlign:'center', 
                                    fontSize: '0.85em', 
                                    color: 'var(--pl-grey)'
                                }}>
                                    <strong>Your activation code is a 10-digit number, located on the sticker included within your test kit.</strong>
                                </p>
                            </>
                        </Popup>
                    }

                    <FormField 
                        id='form__fname'
                        fieldName='form__fname'
                        fieldType='text'
                        labelName='First Name'
                        required={true}
                        registerValue={setFormValues}
                        errorText='Please enter your first name/given name.'
                    />
                    <FormField 
                        id='form__lname'
                        fieldName='form__lname'
                        fieldType='text'
                        labelName='Last Name' 
                        required={true}
                        registerValue={setFormValues}
                        errorText='Please enter your surname/family name.'
                    />
                    <FormField 
                        id='form__dob'
                        fieldName='form__dob'
                        fieldType='date'
                        labelName='Date of Birth' 
                        required={true}
                        min={new Date('1900-01-01')}
                        max={new Date()}
                        registerValue={setFormValues}
                        errorText='Please enter a valid date of birth later than 01/01/1900.'
                    />
                </fieldset>
                <fieldset className='form__section'>
                    <legend className='form__section__heading'>Sister Club membership details</legend>
                    <FormField 
                        id='form__email'
                        fieldName='form__email'
                        fieldType='email'
                        labelName='Sister Club Email Address' 
                        required={true}
                        registerValue={setFormValues}
                        errorText='Please provide your Priceline Sister Club email address.'
                    />
                    <FormField 
                        id='form__club-no'
                        fieldName='form__club-no'
                        fieldType='text'
                        labelName='Sister Club Number' 
                        required={true}
                        maxLength={13}
                        pattern={new RegExp('[0-9]{13}')}
                        registerValue={setFormValues}
                        errorText='Your club number should be a 13-digit number.'
                    >
                        <div 
                            className='form__field__info-icon'
                            aria-label='Information icon'
                            title='What is this?'
                            onClick={toggleSisterClub}
                        >
                                <span>i</span>
                        </div>
                    </FormField>
                    <p className='form__join-link'>Not a Sister Club member? <a href='https://www.priceline.com.au/customer/account/create' target='_blank' rel='noreferrer'>Join now free.</a></p>
                    { showSisterClubPopup && 
                        <Popup close={toggleSisterClub}>
                            <>
                                <img 
                                    src="/priceline-sister-club-card.jpg" 
                                    alt='A diagram showing the location of a Sister Club number on a membership card' 
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        maxWidth: '300px',
                                        margin: '15px auto 2em auto',
                                        borderRadius: '0.5em',
                                        boxShadow: '0 0 15px 0 rgb(0 0 0 / 0.2)'
                                    }}
                                />
                                <p style={{
                                    textAlign:'center', 
                                    fontSize: '0.85em', 
                                    color: 'var(--pl-grey)'
                                }}>
                                    <strong>You can find your number on the back of your Sister Club card or at the bottom of any Sister Club email you have received.</strong>
                                </p>
                            </>
                        </Popup>
                    }
                </fieldset>

                <fieldset className='form__section'>
                    <FormField
                        id='form__consent'
                        fieldName='form__consent'
                        fieldType='checkbox'
                        labelName={privacy}
                        required={true}
                        registerValue={setFormValues}
                        errorText='This field is mandatory.'
                    />
                    <FormField
                        id='form__marketing-consent'
                        fieldName='form__marketing-consent'
                        fieldType='checkbox'
                        labelName={marketingConsent}
                        registerValue={setFormValues}
                    />

                    { showConsentPopup && 
                        <Popup close={toggleConsent}>
                            <InformedConsent />
                        </Popup>
                    }
                </fieldset>
                <span className='form__submit__ctn'>
                    <input
                        id='form__submit__button'
                        className='form__submit'
                        disabled={formLoading || undefined}
                        type='submit'
                        value={formLoading ? ' ' : 'Register'}
                        onClick={handleClick}
                    />
                    {formLoading && 
                        <span className='form__submit__loading' aria-label='Loading Icon'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    }
                </span>
                {!formLoading && formSubmitError &&
                    <p className='form__submit__error'>{formSubmitError}</p>
                }
            </form>

            <div className='widget__logo'>
                <img 
                    src='/priceline_pharmacy-logo.svg'
                    alt='Priceline Logo'
                    className='widget__logo--priceline'
                />
                <img 
                    src='/powered_by_mydna-logo.svg'
                    alt='myDNA Logo'
                    className='widget__logo--myDNA'
                />
            </div>
        </div>
    )
}