import React from 'react'

import Header from './components/Header'
import Widget from './components/Widget'
import Footer from './components/Footer';

function App() {
    return (
        <>
            <Header />
            <main>
                <Widget />
            </main>
            <Footer />
        </>
    );
}

export default App;
