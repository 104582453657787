import React from 'react';
import '../styles/Footer.css'

export default function Footer(){
    const currentYear = new Date().getFullYear();

    const footerItems = [
        { label: 'Privacy Policy', link: 'https://www.priceline.com.au/priceline-policies/privacy-policy' },
        { label: 'Terms & Conditions', link: 'https://www.priceline.com.au/priceline-policies/terms-and-conditions' },
    ]

    return (
        <footer className='footer'>
            <p className='footer__item'>&copy; {currentYear} myDNA Life Australia Pty Ltd.</p>
            {
                footerItems.map(item => (
                    <React.Fragment key={item.label}>
                        <span className='footer__separator'></span>
                        <p className='footer__item'>
                            <a 
                                className='footer__item__link'
                                href={item.link}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {item.label}
                            </a>
                        </p>
                    </React.Fragment>
                ))
            }
        </footer>
    )
}